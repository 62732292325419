import { Container, Row, Col, Card, Button, Form, Spinner, Alert } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from "../config/supabaseClient"
import { UserContext } from '../App2'
const ResetPassword = () => {
    const { profile, session } = useContext(UserContext)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [formError, setFormError] = useState('')
    const navigate = useNavigate()
    const handleSubmitReg = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setFormError('Passwords do not match')
            return
        }
        supabase.auth.updateUser({
            password: password,
        }).then(({ data, error }) => {
            if (error) {
                console.log(error)
                setFormError(error.message)
            } else {
                setFormError(null)
                navigate('/')
            }
        })
    }
    useEffect(() => {
        if (!session) {
            navigate('/login')
        }
    }, [])
    return (
                <>
                    <h1 className='display-1 text-center my-5'>Enter new password</h1>
                    <Col sm={10} md={6} className="mx-auto">
                        <Card style={{ width: '90%' }} className="mx-auto">
                            <Card.Body>
                                <Form onSubmit={handleSubmitReg}>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={session?.user.email} disabled />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="confirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </Form.Group>
                                    {formError && <Alert variant="danger">{formError}</Alert>}
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </>
    )
}

export default ResetPassword