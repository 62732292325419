import { Container, Row, Col, Card, Button, Form, ListGroup, Nav } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom"
import supabase from "../../config/supabaseClient"
import ViewDrivers from './ViewDrivers'
import ViewChecklists from './ViewChecklists'
import moment from 'moment/moment'
import { UserContext } from '../../App2'
const Admin = () => {
    const { profile, session } = useContext(UserContext)
    const [checklists, setChecklists] = useState([])
    const [incompleteChecklists, setIncompleteChecklists] = useState()
    const [warning, setWarning] = useState()
    const [expired, setExpired] = useState()
    const location = useLocation()
    let tab = location.pathname.split('/')[2]
    const navigate = useNavigate()

    useEffect(() => {
        if(tab === undefined) {
            navigate('/admin/drivers')
        }

    }, [])
    return (
        !profile?.isadmin ? <h5 className='display-2 text-center my-3'>Access denied</h5> :
            <Container className='mt-3'>
                <Outlet />
                
            </Container>
    )
}

export default Admin