import { Container, Row, Col, Card, Button, Form, Image } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import supabase from '../../../config/supabaseClient'

const ProfileCard = (props) => {
    const [profile, setProfile] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const { data, error } = await supabase
                    .from('user_profiles')
                    .select('*')
                    .eq('user_id', props.user_id)
                if (error) throw error
                setProfile(data[0])
                setLoading(false)
            } catch (error) {
                setError(error.message)
            }
        }
        fetchProfile()
    }, [])

    if (loading) return <h5 className='text-center'>Loading...</h5>
    if (error) return <h5 className='text-center'>{error}</h5>
    if (profile)
    return (
            <Card className='h-100 shadow'>
                <Card.Body className='text-start'>
                    <Card.Title>Profile</Card.Title>
                    <Card.Text>ID: {profile.user_simple_id}</Card.Text>
                    <Card.Text>Name: {profile.fname} {profile.lname}</Card.Text>
                    <Card.Text>Email: {profile.email}</Card.Text>
                    <Card.Text>Phone: {profile.phone}</Card.Text>
                </Card.Body>
                <Card.Footer className='text-end'>
                    <Button variant='link' onClick={() => navigate(`/resetpassword`)} >Reset Password</Button>
                </Card.Footer>
            </Card>
    )
}

export default ProfileCard

