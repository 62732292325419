import { Container, Row, Col, Card, Button, Form, Spinner, Alert, Modal } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { Navigate, useNavigate, redirect } from "react-router-dom"
import supabase from "../config/supabaseClient"
import { UserContext } from '../App2'

const TermsandConditionsModal = () => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    return (
        <>
            <Button variant="link" onClick={handleShow}>
                By using this website you agree to the privacy policy.
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Galaxy Cars Woking Privacy Policy</strong>
                    </p>
                    <p>
                        This privacy policy will explain how our organization uses the personal
                        data we collect from you when you use our website.
                    </p>
                    <p>
                        Topics:
                    </p>
                    <ul type="disc">
                        <li>
                            What data do we collect?
                        </li>
                        <li>
                            How do we collect your data?
                        </li>
                        <li>
                            How will we use your data?
                        </li>
                        <li>
                            How do we store your data?
                        </li>
                        <li>
                            Marketing
                        </li>
                        <li>
                            What are your data protection rights?
                        </li>
                        <li>
                            What are cookies?
                        </li>
                        <li>
                            How do we use cookies?
                        </li>
                        <li>
                            What types of cookies do we use?
                        </li>
                        <li>
                            How to manage your cookies
                        </li>
                        <li>
                            Privacy policies of other websites
                        </li>
                        <li>
                            Changes to our privacy policy
                        </li>
                        <li>
                            How to contact us
                        </li>
                        <li>
                            How to contact the appropriate authorities
                        </li>
                    </ul>
                    <p>
                        <strong>What data do we collect?</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking collects the following data:
                    </p>
                    <ul type="disc">
                        <li>
                            Name
                        </li>
                        <li>
                            Email
                        </li>
                        <li>
                            Phone number
                        </li>
                        <li>
                            Any images you choose to upload
                        </li>
                        <li>
                            Any documents you choose to upload
                        </li>
                    </ul>
                    <p>
                        <strong>How do we collect your data?</strong>
                    </p>
                    <p>
                        You directly provide Galaxy Cars Woking with most of the data we collect.
                        We collect data and process data when you:
                    </p>
                    <ul type="disc">
                        <li>
                            Register online.
                        </li>
                        <li>
                            Voluntarily upload any documents or images.
                        </li>
                        <li>
                            Use or view our website via your browser’s cookies.
                        </li>
                        <li>
                            Galaxy Cars Woking may also receive your data indirectly from the
                            following sources:<strong></strong>
                        </li>
                    </ul>
                    <p>
                        <strong>How will we use your data?</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking collects your data so that we can:
                    </p>
                    <ul type="disc">
                        <li>
                            Process and manage your account.
                        </li>
                        <li>
                            Provide any reminders to you
                        </li>
                    </ul>
                    <p>
                        <strong>How do we store your data?</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking securely stores your data at the AWS eu-west-2 facility.
                        Your data is secured using JWT tokens so that only you or admins can view
                        your data.
                    </p>
                    <p>
                        <strong>Marketing</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking will not use you data for marketing
                    </p>
                    <p>
                        <strong>What are your data protection rights?</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking would like to make sure you are fully aware of all your
                        data protection rights. Every user is entitled to the following:
                    </p>
                    <p>
                        <strong>The right to access</strong>
                        – You have the right to request Galaxy Cars Woking for copies of your
                        personal data. We may charge you a small fee for this service.
                    </p>
                    <p>
                        <strong>The right to rectification</strong>
                        – You have the right to request that Galaxy Cars Woking correct any
                        information you believe is inaccurate. You also have the right to request
                        Galaxy Cars Woking to complete the information you believe is incomplete.
                    </p>
                    <p>
                        <strong>The right to erasure</strong>
                        – You have the right to request that Galaxy Cars Woking erase your personal
                        data, under certain conditions.
                    </p>
                    <p>
                        <strong>The right to restrict processing</strong>
                        – You have the right to request that Galaxy Cars Woking restrict the
                        processing of your personal data, under certain conditions.
                    </p>
                    <p>
                        <strong>The right to object to processing</strong>
                        – You have the right to object to Galaxy Cars Woking’s processing of your
                        personal data, under certain conditions.
                    </p>
                    <p>
                        <strong>The right to data portability</strong>
                        – You have the right to request that Galaxy Cars Woking transfer the data
                        that we have collected to another organization, or directly to you, under
                        certain conditions.
                    </p>
                    <p>
                        If you make a request, we have one month to respond to you. If you would
                        like to exercise any of these rights, please contact us at
                    </p>
                    <p>
                        Call us at: 01483 777777
                    </p>
                    <p>
                        <strong>Cookies</strong>
                    </p>
                    <p>
                        Cookies are text files placed on your computer to collect standard Internet
                        log information and visitor behaviour information. When you visit our
                        websites, we may collect information from you automatically through cookies
                        or similar technology.
                    </p>
                    <p>
                        For further information, visit allaboutcookies.org.
                    </p>
                    <p>
                        <strong>How do we use cookies?</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking uses cookies in a range of ways to improve your
                        experience on our website, including:
                    </p>
                    <ul type="disc">
                        <li>
                            Keeping you signed in<strong></strong>
                        </li>
                    </ul>
                    <p>
                        <strong>What types of cookies do we use?</strong>
                    </p>
                    <p>
                        There are a number of different types of cookies, however, our website
                        uses:
                    </p>
                    <ul type="disc">
                        <li>
                            Functionality – Galaxy Cars Woking uses these cookies so that we
                            recognize you on our website and remember your previously selected
                            preferences. These could include what language you prefer and location
                            you are in. A mix of first-party and third-party cookies are used.        <strong></strong>
                        </li>
                    </ul>
                    <p>
                        <strong>How to manage cookies</strong>
                    </p>
                    <p>
                        You can set your browser not to accept cookies, and the above website tells
                        you how to remove cookies from your browser. However, in a few cases, some
                        of our website features may not function as a result.
                    </p>
                    <p>
                        <strong>Privacy policies of other websites</strong>
                    </p>
                    <p>
                        The Galaxy Cars Woking website may contain links to other websites. Our
                        privacy policy applies only to our website, so if you click on a link to
                        another website, you should read their privacy policy.
                    </p>
                    <p>
                        <strong>Changes to our privacy policy</strong>
                    </p>
                    <p>
                        Galaxy Cars Woking keeps its privacy policy under regular review and places
                        any updates on this web page. This privacy policy was last updated on 25
                        January 2023.
                    </p>
                    <p>
                        <strong>How to contact us</strong>
                    </p>
                    <p>
                        If you have any questions about Galaxy Cars Woking’s privacy policy, the
                        data we hold on you, or you would like to exercise one of your data
                        protection rights, please do not hesitate to contact us.
                    </p>
                    <p>
                        Call us: 01483 777777
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
const Login2 = () => {
    const { profile, session } = useContext(UserContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [formError, setFormError] = useState('')
    const [authMode, setAuthMode] = useState('login')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    const handleSubmitLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        supabase.auth.signInWithPassword({ email, password })
            .then(({ data, error }) => {
                if (error) {
                    setFormError(error.message)
                    setLoading(false)
                } else {
                    // check if user is active from user_profiles table
                    supabase
                        .from('user_profiles')
                        .select('active')
                        .eq('user_id', data.user.id)
                        .then(({ data, error }) => {
                            if (error) {
                                setFormError(error.message)
                                setLoading(false)
                            } else {
                                if (data[0].active) {
                                    setFormError(null)
                                    navigate('/')
                                } else {
                                    supabase.auth.signOut()
                                    setFormError('Your account has been disabled. Please contact support.')
                                    setLoading(false)
                                }
                            }
                        })
                }
            })
    }
    const handleSubmitReg = async (e) => {
        e.preventDefault()
        if (password !== password2) {
            setFormError('Passwords do not match')
            return
        }
        supabase.auth.signUp({
            email: email,
            password: password,
        }).then(({ data, error }) => {
            if (error) {
                console.log(error)
                setFormError(error.message)
            } else {
                setFormError(null)
                redirect('/registration')
            }
        })
    }
    const handleSubmitReset = async (e) => {
        e.preventDefault()
        supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'https://drivers.galaxycarswoking.co.uk/resetpassword',
        }).then(({ data, error }) => {
            if (error) {
                console.log(error)
                setFormError(error.message)
            } else {
                setFormError('Check your email for a link to reset your password.')
            }
        })
    }
    useEffect(() => {
        if (session) {
            console.log('redirecting')
            navigate('/')
        }
    }, [])
    return (
        <Container>
            {authMode === 'login' ?
                <>
                    <h1 className='display-1 text-center my-5'>Login</h1>
                    <Col sm={10} md={6} className="mx-auto">
                        <Card style={{ width: '90%' }} className="mx-auto shadow">
                            <Card.Body>
                                <Form onSubmit={handleSubmitLogin}>
                                    <Form.Group className="mb-3" controlId="fname">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <Button onClick={() => setAuthMode('reset')} variant='link'>Click here to reset password</Button>
                                    </Form.Group>
                                    {formError && <Alert variant='danger'>{formError}</Alert>}
                                    <Button className='float-end' variant="primary" type='submit'>Submit</Button>
                                    {loading && <Spinner animation="border" variant="primary" />}

                                    <TermsandConditionsModal />
                                </Form>
                            </Card.Body>
                        </Card>
                        {
                            process.env.NODE_ENV === 'development' ?
                                <div className='text-center'>
                                    <Button onClick={() => setAuthMode('register')} variant='link'>Click here to register</Button>
                                </div>
                                :
                                null
                        }
                    </Col>
                </>
                :
                authMode === 'register' ?

                    <>
                        <h1 className='display-1 text-center my-5'>Driver Registration</h1>
                        <Col sm={10} md={6} className="mx-auto">
                            <Card style={{ width: '90%' }} className="mx-auto">
                                <Card.Body>
                                    <Form onSubmit={handleSubmitReg}>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="password">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="password2">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                                        </Form.Group>
                                        <Button className='float-end' variant="primary" type='submit'>Submit</Button>
                                        {formError && <p className="error">{formError}</p>}
                                    </Form>

                                </Card.Body>
                            </Card>
                            <Button onClick={() => setAuthMode('login')} variant='link'>Click here to login</Button>
                        </Col>
                    </>
                    : authMode === 'reset' ?
                        <>
                            <h1 className='display-1 text-center my-5'>Password Reset</h1>
                            <Col sm={10} md={6} className="mx-auto">
                                <Card style={{ width: '90%' }} className="mx-auto">
                                    <Card.Body>
                                        <Form onSubmit={handleSubmitReset}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </Form.Group>
                                            <Button className='float-end' variant="primary" type='submit'>Submit</Button>
                                            {formError && <p className="error">{formError}</p>}
                                        </Form>

                                    </Card.Body>
                                </Card>
                                <Button onClick={() => setAuthMode('login')} variant='link'>Click here to login</Button>
                            </Col>
                        </>
                        : null
            }

        </Container>
    )

}
export default Login2
