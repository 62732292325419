import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom"
import { useState, useEffect, useContext, createContext } from "react";
import { Spinner } from "react-bootstrap";
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
// pages
import Home2 from "./pages/driver/Home2";
import Registration from "./pages/Registration";
import Checklist from "./pages/driver/checklist/Checklist";
import ViewChecklist from "./pages/driver/checklist/ViewChecklist";
import Admin from "./pages/admin/Admin";
import Redirector from "./pages/Redirector";
import ViewDocsAdmin from "./pages/admin/ViewExpiringDocs";
import EditDriver from "./pages/admin/EditDriver";
import DocTypes from "./pages/admin/docs/DocTypes";
import ResetPassword from "./pages/ResetPassword";
import ResetEmail from "./pages/ResetEmail";
import ViewDocsAlt from "./pages/admin/ViewDocs";
import RecentlyAddedDocs from "./pages/admin/RecentlyAddedDocs";
// helpers and componenets
import NavbarComponent from "./components/Nabar";
import UseSession from "./contexts/UseSession";
import ProtectedRoutes from "./components/ProtectedRoutes";
import AdminProtectedRoutes from "./components/AdminProtectedRoutes";
import Login2 from "./pages/Login2";
import ViewDrivers from "./pages/admin/ViewDrivers";
import ViewChecklists from "./pages/admin/ViewChecklists";
import AddVehicle from "./pages/driver/vehicle/AddVehicle";
import AddDoc from "./pages/driver/docs/AddDoc";
import ViewDocs from "./pages/driver/docs/ViewDocs";
import './ftable.css';
const { version } = packageJson

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <ProtectedRoutes />,
                children: [
                    {
                        path: "/",
                        element: <Redirector />,
                    },
                    {
                        path: "resetpassword",
                        element: <ResetPassword />,
                    },
                    {
                        path: "resetemail",
                        element: <ResetEmail />,
                    },
                    {
                        path: "driver",
                        element: <Home2 />,
                    },
                    {
                        path: "/driver/checklist",
                        element: <Checklist />,
                    },
                    {
                        path: "/driver/viewchecklist",
                        element: <ViewChecklist />,
                    },
                    {
                        path: "/driver/addvehicle",
                        element: <AddVehicle />,
                    },
                    {
                        path: "/driver/newdocument",
                        element: <AddDoc />,
                    },
                    {
                        path: "/driver/viewdocuments",
                        element: <ViewDocs />,
                    },
                    {
                        path: "registration",
                        element: <Registration />,
                    },
                    {
                        path: "admin",
                        element: <Admin />,
                        children: [
                            {
                                path: "drivers",
                                element: <ViewDrivers />,
                            },
                            {
                                path: "drivers/:user_id",
                                element: <EditDriver />,
                            },
                            {
                                path: "checklists",
                                element: <ViewChecklists />,
                            },
                            {
                                path: "expiring-documents",
                                element: <ViewDocsAdmin />,
                            },
                            {
                                path: "documents",
                                element: <ViewDocsAlt />,
                            },
                            {
                                path: "doctypes",
                                element: <DocTypes />,
                            },
                            {
                                path: "recent-documents",
                                element: <RecentlyAddedDocs />,
                            }
                        ]
                    },
                ]
            },
            {
                path: "login",
                element: <Login2 />,
            },

        ],
    },
]);
export const UserContext = createContext({
    session: null,
    profile: null,
});

const Loading = () => <Spinner animation="border" variant="primary" />;

function App2() {
    const isProduction = process.env.NODE_ENV === 'production';

    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
            <RouterProvider router={router} />
        </CacheBuster>
    )
}
function Layout() {
    const supashipUserInfo = UseSession();
    return (
        <UserContext.Provider value={supashipUserInfo}>
            <NavbarComponent />
            <Outlet />
            <p className="text-muted text-center">version: {version}</p>
        </UserContext.Provider>
    );
}

export default App2;
