import React, { useState, useEffect } from 'react'
import supabase from '../../../config/supabaseClient'
import { Button, Form, Container, Toast, Modal, Card, Row, Col, Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { set, useForm } from "react-hook-form";

const DeleteModal = (props) => {
    const navigate = useNavigate()
    const handleDelete = () => {
        let image_urls = []
        supabase
            .from('documents')
            .select('image_urls')
            .match({ user_id: props.user_id })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    if (data.length > 0) {
                        data.forEach(element => {
                            for (let i = 0; i < element.image_urls.length; i++) {
                                image_urls.push(element.image_urls[i])
                            }
                        })
                        supabase.storage.from('documents').remove(image_urls)
                    }
                }
            })

        supabase.from('vehicle').delete().match({ user_id: props.user_id })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    supabase.from('documents').delete().match({ user_id: props.user_id })
                        .then(({ data, error }) => {
                            if (error) {
                                console.log(error)
                            } else {
                                supabase.from('checklist').delete().match({ user_id: props.user_id })
                                    .then(({ data, error }) => {
                                        if (error) {
                                            console.log(error)
                                        } else {
                                            // set the user to inactive in the user_profiles table
                                            supabase.from('user_profiles').update([{ active: false, user_simple_id: null }]).match({ user_id: props.user_id })
                                                .then(({ data, error }) => {
                                                    if (error) {
                                                        console.log(error)
                                                    } else {
                                                        navigate(-1)
                                                    }
                                                })
                                        }
                                    }
                                    )
                            }
                        })
                }
            })
    }
    return (
        <Modal
            {...props}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete Driver
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Deleting this driver will remove all associated checklists and documents.</p>
                <p>The drivers ID will be removed and their account set to inactive.</p>
                <p>Their email and password will remain on record but they will not be able to sign in.</p>
                <p>Are you sure you want to delete this driver?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


const EditProfile = (props) => {
    const [id, setId] = useState('')
    const [newID, setNewID] = useState()
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isadmin, setIsadmin] = useState(false)
    const [isactive, setIsactive] = useState(false)
    const [allids, setAllids] = useState([])
    const [changed, setChanged] = useState(false)
    const [formerror, setFormerror] = useState('')
    const [goodID, setGoodID] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();
    const fetchProfile = () => {
        supabase
            .from('user_profiles')
            .select('*')
            .eq('user_id', props.user_id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    const driver = data[0]
                    setId(driver.user_simple_id)
                    setFname(driver.fname)
                    setLname(driver.lname)
                    setEmail(driver.email)
                    setPhone(driver.phone)
                    setIsadmin(driver.isadmin)
                    setIsactive(driver.active)
                }
            })
    }
    const handleIdChange = (inputID) => {
        //check if id is already in use and if so, set error
        if ((allids.includes(parseInt(inputID)) && parseInt(inputID) !== parseInt(id)) || (inputID === '')) {
            setGoodID(true)
            setChanged(false)
        }
        else {
            setGoodID(false)
            setNewID(inputID)
            setChanged(true)
            setFormerror('')
        }
    }
    const handleAdminChange = (e) => {
        setIsadmin(e.target.checked)
        setId('')
        setChanged(true)

    }
    const handleUpdate = data => {
        supabase
            .from('user_profiles')
            .update([
                {
                    user_simple_id: data.isadmin || !data.active ? null : data.user_simple_id,
                    fname: data.fname,
                    lname: data.lname,
                    email: data.email,
                    phone: data.phone,
                    isadmin: data.isadmin,
                    active: data.active
                },
            ])
            .match({ user_id: props.user_id })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormerror(error.message)
                } else {
                    setChanged(false)
                    setShowSuccess(true)
                    fetchProfile()
                }
            })
        setTimeout(() => {
            setShowSuccess(false)
        }, 3000)
    }

    useEffect(() => {
        supabase
            .from('user_profiles')
            .select('*')
            .eq('user_id', props.user_id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    const driver = data[0]
                    setValue('user_simple_id', driver.user_simple_id)
                    setId(driver.user_simple_id)
                    setValue('fname', driver.fname)
                    setValue('lname', driver.lname)
                    setValue('email', driver.email)
                    setEmail(driver.email)
                    setValue('phone', driver.phone)
                    setValue('isadmin', driver.isadmin)
                    setValue('active', driver.active)

                    supabase
                        .from('all_user_ids')
                        .select('*')
                        .then(({ data, error }) => {
                            if (error) {
                                console.log(error)
                            } else {
                                //remove current user id from list
                                data = data.filter((id) => id.user_simple_id !== driver.user_simple_id)
                                //save all ids to state
                                setAllids(data.map((id) => id.user_simple_id))
                            }
                        })
                }
            })

        setChanged(false)
    }, [props])

    return (
        <Row className='justify-content-lg-center'>
            <Col md={5}>
                <Form onSubmit={handleSubmit(handleUpdate)}>
                    <Card className='shadow'>
                        <DeleteModal
                            show={showDeleteModal}
                            onHide={() => { setShowDeleteModal(false); setChanged(false) }}
                            user_id={props.user_id}
                        />
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="user_simple_id">
                                <Form.Label>ID</Form.Label>
                                <Form.Control type="text" placeholder="Enter ID"
                                    {...register('user_simple_id',
                                        {
                                            validate: {
                                                notNull: value => { return !allids.includes(parseInt(value)) || parseInt(value) === parseInt(id) },

                                                // check if id in not empty if is active
                                                notEmpty: value => { if (getValues('active') && value === null) return false; return true },

                                            },
                                            onChange: (e) => {
                                                if (getValues('admin') || !getValues('active')) {
                                                    e.target.value = ''
                                                }
                                            }
                                        }
                                    )}
                                    isInvalid={errors.user_simple_id}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.user_simple_id && <p>ID invalid or in use (delete id if admin or inactive)</p>}</Form.Control.Feedback>
                                <Form.Text id="idhelp" muted>
                                    ID must be unique and not empty if active. Leave blank if admin or inactive.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fname">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter first name"
                                    {...register('fname', { required: true })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="lname">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter last name"
                                    {...register('lname', { required: true })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Enter email"
                                    defaultValue={email}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="Enter phone"
                                    {...register('phone', { required: true, maxLength: 12, pattern: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i })}
                                    isInvalid={errors.phone}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.phone && <p>Invalid phone number</p>}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="isadmin">
                                <Form.Check type="checkbox" label="Admin"
                                    {...register('isadmin', {
                                        required: false,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                setValue('user_simple_id', null);
                                            }
                                            if (!e.target.checked && getValues('active')) {
                                                if (id){
                                                    setValue('user_simple_id', parseInt(id))
                                                } else {
                                                    setValue('user_simple_id', 0)
                                                }
                                            }
                                        }
                                    })}
                                />
                            </Form.Group>
                            <Form.Group controlId="active">
                                <Form.Check type="checkbox" label="Active"
                                    {...register('active', {
                                        required: false,
                                        onChange: (e) => {
                                            if (e.target.checked && !getValues('isadmin')) {
                                                setValue('user_simple_id', parseInt(id))
                                            }
                                            if (!e.target.checked) {
                                                setValue('user_simple_id', null)
                                            }
                                        }
                                    })}
                                />
                            </Form.Group>
                            {formerror && <Alert variant='danger'>{formerror}</Alert>}
                            {changed && <Alert variant='success'>Changes saved</Alert>}
                            {showSuccess && <Alert variant='success'>Profile updated</Alert>}
                        </Card.Body>
                        <Card.Footer className='bg-light'>
                            <div className='text-end'>
                                <Button variant="primary" type="submit">Submit</Button>

                                <Button variant="danger" className='mx-2' onClick={() => setShowDeleteModal(true)}>Delete</Button>

                            </div>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

export default EditProfile