import { Container, Row, Col, Badge, Button, Spinner} from 'react-bootstrap'
import { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../config/supabaseClient'
import { UserContext } from '../../App2'
import DocImageModal from './DocImageModal'
import moment from 'moment/moment'
import DocEditModal from './docs/DocEditModal'
import CreateDocModal from './docs/CreateDocModal'
const FilterableTable = require('react-filterable-table');

const ViewDocsAlt = (props) => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState(null)
    const [showImageModal, setShowImageModal] = useState(false)
    const [image_urls, setImageUrls] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [doc, setDoc] = useState(null)
    const [docTypes, setDocTypes] = useState([])
    const [fields, setFields] = useState(null)
    const [loading, setLoading] = useState(true)


    const renderModalButton = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => { setShowEditModal(true); setDoc(props.record) }}>
                    Edit
                </Button>
                <Button variant='primary' className='mx-2' onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                    View all driver documents
                </Button>
            </div>

        )
    }
    const renderExpiry = (doc) => {
        let reminder_days = docTypes.find((docType) => docType.id === doc.doc_type).reminder_days
        let startDate = moment(doc.expiry)
        let reminderdays = moment(reminder_days)
        let endDate = moment()
        let duration = moment.duration(startDate.diff(endDate))
        let days = duration.asDays()
        let reminder = moment.duration(reminderdays.diff(endDate))
        let reminderdaysleft = reminder.asDays()
        if (!doc.expiry) {
            return (
                <div>
                    No expiry date
                </div>
            )
        }
        if (days < 0) {
            return (
                <div className='bg-danger text-light'>
                    {doc.expiry ? doc.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else if (days < reminderdays) {
            return (
                <div className='bg-warning'>
                    {doc.expiry ? doc.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else {
            return (
                <div>
                    {doc.expiry ? doc.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        }
    }

    const renderName = (props) => {
        return (
            <div onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                {props.record.fname} {props.record.lname}
            </div>
        )
    }
    const renderID = (props) => {
        return (
            <div onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                {props.record.user_simple_id}
            </div>
        )
    }
    const renderDate = (props) => {
        let docs = props.record.documents
        let doc = docs.find(doc => doc.doc_type === props.field.name)
        let required = docTypes.find((docType) => docType.id === props.field.name).required
        if (doc) {
            return (
                <div onClick={() => { setShowEditModal(true); setDoc(doc) }}>
                    {renderExpiry(doc)}
                </div>
            )
        } else if (required) {
            return (
                <Badge bg='danger' text='light' className='opacity-50'>
                    Missing
                </Badge>
            )
        } else {
            return (
                <Badge bg='warning' text='dark' className='opacity-50'>
                    Missing
                </Badge>
            )
        }
    }
    const createFields = () => {
        let fields = []
        fields.push({ name: 'user_simple_id', displayName: "Driver ID", inputFilterable: true, exactFilterable: false, sortable: true, render: renderID })
        fields.push({ name: 'fname', displayName: "Driver Name", inputFilterable: true, exactFilterable: false, sortable: true, render: renderName })
        fields.push({ name: 'reg', displayName: "Vehicle Reg", inputFilterable: true, exactFilterable: false, sortable: true })
        docTypes.map((type) => {
            fields.push({ name: type.id, displayName: type.doc_name, inputFilterable: true, exactFilterable: false, sortable: true, render: renderDate })
        })
        return fields
    }
    const fetchDocs = () => {
        setLoading(true)
        supabase
            .from('document_types')
            .select('*')
            .order('order', { ascending: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setDocTypes(data)
                }
            })

        supabase
            .from('user_profiles')
            .select('*, documents(*), vehicle(reg)')
            .filter('active', 'eq', true)
            .filter('isadmin', 'eq', false)
            // .filter('documents:active', 'eq', true)
            .filter('documents.active', 'eq', true)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setRes(data)
                    data.map((user) => {
                        // take vehicle reg from user_profiles 
                        let vehicle_reg = user.vehicle.reg
                        // place vehicle reg in new field in user_profiles called reg
                        user.reg = vehicle_reg
                    })
                    setTimeout(() => {
                        setLoading(false)
                    } , 500)
                }
            })
    }

    useLayoutEffect(() => {
        const fetchDocs = async () => {
            //get list of doc_types 
            await supabase
                .from('document_types')
                .select('*')
                .order('order', { ascending: true })
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    }
                    else {
                        setDocTypes(data)
                        
                    }
                })

            await supabase
                .from('user_profiles')
                .select('*, documents(*), vehicle(reg)')
                .filter('active', 'eq', true)
                .filter('isadmin', 'eq', false)
                // .filter('documents:active', 'eq', true)
                .filter('documents.active', 'eq', true)
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    }
                    else {
                        setRes(data)
                        
                        data.map((user) => {
                            // take vehicle reg from user_profiles 
                            let vehicle_reg = user.vehicle.reg
                            // place vehicle reg in new field in user_profiles called reg
                            user.reg = vehicle_reg
                        })
                        setTimeout(() => {
                            setLoading(false)
                        } , 500)
                    }
                })
        }
        fetchDocs()
        setFields(createFields())
    }, [])
    return (
        <div >
            <Row className='p-2 py-3'>
                <Col sm={7}>
                    <h1 className='text-start'>Documents</h1>
                </Col>
                <Col sm={5} className='text-end'>
                    <Button variant="info" className='m-2' onClick={() => {fetchDocs(); setLoading(true)}}>
                    { loading ?<Spinner animation="border" variant="dark" size='sm' /> :
                    <>Refresh <i className="fa fa-refresh" aria-hidden="true" ></i></>}
                </Button>
                    {/* <CreateDocModal /> */}
                    {showEditModal ? <DocEditModal item={doc} show={showEditModal} onHide={() => { setShowEditModal(false); setDoc(null) }} /> : null}
                    <Button variant="primary" className='m-2' onClick={() => navigate('/admin/doctypes')} >Edit Document Types</Button>
                </Col>
            </Row>
                    
            <FilterableTable
                namespace="Documents"
                initialSort="user_simple_id"
                initialSortDir={true}
                data={res ? res : []}
                fields={createFields()}
                pageSize={200}
                pageSizes={[5, 10, 20, 50, 100, 200]}
                noRecordsMessage="There are no documents to display"
                noFilteredRecordsMessage="No documents match your filters!"
                tableClassName="table table-sm table-striped table-bordered table-hover table-responsive shadow position-absolute start-0 text-center"
            /> 
        </div>
    )
}

export default ViewDocsAlt
