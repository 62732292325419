import { Card, Button, Stack, Badge } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import moment from 'moment/moment'

const ChecklistCard = (props) => {

    const [checklist, setChecklist] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [daysAgo, setDaysAgo] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        const fetchChecklist = async () => {
            await supabase
            .from('checklist')
            .select('*')
            .eq('user_id', props.user_id)
            .order('created_at', { ascending: false })
                .limit(1)
                .then(({ data, error }) => {
                    if (error) {
                        setError(error.message)
                        setLoading(false)
                    }
                    else {
                        if (data.length === 0) {
                            setLoading(false)
                            return
                        } else {
                            // use moment to get how many days ago the checklist was added
                            const daysAgo = moment().diff(data[0].created_at, 'days')
                            setDaysAgo(daysAgo)
                            setChecklist(data[0].created_at)
                            setLoading(false)
                        }
                    }
                })

        }
        fetchChecklist()
    }, [])

    if (loading) return <h5 className='text-center'>Loading...</h5>
    if (checklist)
        return (
                <Card className='h-100 shadow'>
                    <Card.Body>
                    <Card.Title>Checklists</Card.Title>
                    <p>Days since last checklist: 
                        {daysAgo > 0 ? <Badge bg='danger' className='ms-2'>{daysAgo} days ago</Badge> : <Badge bg='success' className='ms-2'>{daysAgo}</Badge>}
                    </p>
                        
                    <Stack gap={1} className='justify-content-center'>
                        <Button onClick={() => navigate('checklist')} variant='success'>Add Today's Checklist</Button>
                        </Stack>
                    </Card.Body>
                    <Card.Footer className='text-end'>
                        <Button variant='link' onClick={() => navigate('viewchecklist')}>View Previous Checklists</Button>
                </Card.Footer>
                </Card>
        )
    else
        return (
                <Card className='h-100 shadow'>
                    <Card.Body>
                    <Card.Title>Checklists</Card.Title>
                        <p className='text-start'>No checklists added yet</p>
                        <Stack gap={2} className='justify-content-center'>
                        <Button onClick={() => navigate('checklist')} className='m-2' variant='success'>Add Today's Checklist</Button>
                        </Stack>
                    </Card.Body>
                    <Card.Footer className='text-end'>
                        <Button variant='link' onClick={() => navigate('viewchecklist')}>View Previous Checklists</Button>
                </Card.Footer>
                </Card>
        )
}

export default ChecklistCard