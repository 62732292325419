import { Container, Row, Col, Card, Button, Form, Spinner, Alert } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import supabase from "../config/supabaseClient"
import { UserContext } from '../App2'
const ResetEmail = () => {
    const { profile, session } = useContext(UserContext)
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [formError, setFormError] = useState('')
    const navigate = useNavigate()
    const handleSubmitReg = async (e) => {
        e.preventDefault()
        if (email !== confirmEmail) {
            setFormError('Emails do not match')
            return
        }
        supabase.auth.updateUser({
            email: email,
        }).then(({ data, error }) => {
            if (error) {
                console.log(error)
                setFormError(error.message)
            } else {
                setFormError(null)
                navigate('/')
            }
        })
    }
    useEffect(() => {
        if (!session) {
            navigate('/login')
        }
    }, [])
    return (
                <>
                    <h1 className='display-1 text-center my-5'>Enter new Email</h1>
                    <Col sm={10} md={6} className="mx-auto">
                        <Card style={{ width: '90%' }} className="mx-auto">
                            <Card.Body>
                                <Form onSubmit={handleSubmitReg}>
                                <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Current Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={session.user.email} disabled />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Confirm Email</Form.Label>
                                        <Form.Control type="email" placeholder="Confirm email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                                    </Form.Group>
                                    {formError && <Alert variant="danger">{formError}</Alert>}
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </>
    )
}

export default ResetEmail