import { Container, Row, Col, Card, Button, Form, Spinner, Modal, Stack } from 'react-bootstrap'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import { v4 as uuidv4 } from 'uuid';

const AddDocModal = (props) => {
    const navigate = useNavigate()
    const { profile, session } = useContext(UserContext)
    const [docName, setDocName] = useState()
    const [expiry, setExpiry] = useState('')
    const [startDate, setStartDate] = useState('')
    const [images, setImages] = useState([])
    const [selectedid, setSelectedId] = useState()
    const [formError, setFormError] = useState()
    const [docTypes, setDocTypes] = useState([])
    const [fileNum, setFileNum] = useState(1)
    const [fileTypes, setFileTypes] = useState(["image/jpg", "application/pdf", "image/png", "image/jpeg"])
    const [selectedType, setSelectedType] = useState('')
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([])
    const [error, setError] = useState()

    const deleteImages = (urls) => {
        supabase.storage.from('documents').remove(urls)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        // check if images are uploaded
        if (images.length === 0) {
            alert('Please upload images')
            return
        }
        //check if doc name is selected
        if (selectedid === undefined) {
            alert('Please select document name')
            return
        }
        //search for active document with same doc type and user id and set to inactive
        supabase
            .from('documents')
            .update({ active: false })
            // .select('active')
            .match({ user_id: users[0].user_id, doc_type: selectedid, active: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormError(error.message)
                } else {
                    console.log('updated')
                }
            })


        //upload images to storage
        let tempFiles = []
        for (let i = 0; i < images.length; i++) {
            let file = images[i][0]
            let fileObject = {
                name: users[0].user_id + "/" + uuidv4(),
                type: file.type,
                size: file.size
            }
            supabase.storage.from('documents').upload(fileObject.name, file, { contentType: file.type })
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    } else {
                        tempFiles.push(fileObject)
                    }
                })
        }
        while (tempFiles.length < images.length) {
            await new Promise(r => { setTimeout(r, 500); setLoading(true) });
        }
        setLoading(false)
        supabase
            .from('documents')
            .insert([
                {
                    user_id: props.user_id,
                    doc_type: selectedid,
                    start_date: startDate === '' ? null : startDate,
                    expiry: expiry === '' ? null : expiry,
                    files: tempFiles,
                }
            ]).then(({ data, error }) => {
                if (error) {
                    console.log(error)
                    setFormError(error.message)
                    deleteImages(tempFiles)
                } else {
                    navigate(0)
                }
            })
    }
    const renderFileUpload = () => {
        let fileUploads = []
        for (let i = 0; i < fileNum; i++) {
            fileUploads.push(
                <Form.Control type="file" placeholder="Select all relevent images"
                    key={i}
                    onChange={(e) => { setImages(current => [...current, e.target.files]); setFileNum(fileNum + 1); }}
                    accept={fileTypes.join(',')}
                    className='my-2'
                />
            )
        }
        return fileUploads
    }
    useEffect(() => {
        setFileNum(1)
        setImages([])

        supabase
            .from('user_profiles')
            .select('*')
            .filter('isadmin', 'eq', false)
            .filter('user_id', 'eq', props.user_id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    if (data.length === 0) {
                        setError('No users found')
                    } else {
                        setUsers(data)
                    }
                }
            })

        supabase
            .from('document_types')
            .select('*')
            .order('order', { ascending: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    setDocTypes(data)
                }
            })
    }, [])

    return (
        <>
            <Button variant='success' className='m-1' onClick={() => setShow(true)}>Add Document</Button>
            <Modal show={show} onHide={() => { setShow(false); setLoading(false); setFileNum(1); setImages([]) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>User</Form.Label>
                            <Form.Control type="text" placeholder="Enter user id" value={users[0]?.fname + ' ' +  users[0]?.lname} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="dname">
                            <Form.Label>Document Name</Form.Label>
                            <Form.Select onChange={(e) => { setSelectedId(e.target.selectedOptions[0].attributes['akey'].value) }}>
                                <option>Select from list</option>
                                {
                                    docTypes.map((docType) => {
                                        return (
                                            <option key={docType.id} akey={docType.id} variant='outline-primary' className='m-1' onSelect={() => console.log('select')} onClick={(e) => setDocName(docType)}>{docType.doc_name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="expiry">
                            <Form.Label>Start Date (leave blank if not applicable)</Form.Label>
                            <Form.Control type="date" onChange={(e) => setStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="expiry">
                            <Form.Label>Expiry Date (leave blank if not applicable)</Form.Label>
                            <Form.Control type="date" onChange={(e) => setExpiry(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="img">
                            <Form.Label>Select File</Form.Label>
                            {renderFileUpload()}
                        </Form.Group>
                        <Form.Text className="text-danger">
                            {formError}
                        </Form.Text>
                        <Stack gap={2} className='my-3'>
                            <Button className='float-end' variant="secondary" onClick={() => { setShow(false); setLoading(false) }}>
                                Close
                            </Button>
                            <Button className='float-end' variant="primary" type='submit'>
                                Submit
                            </Button>
                        </Stack>
                        {
                            loading && <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddDocModal