import { Button, Modal, Form } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import supabase from "../../config/supabaseClient"


const DeleteModal = (props) => {
    const [formerror, setFormerror] = useState('')
    const nav = useNavigate()
    const handleDelete = async (e) => {
        e.preventDefault()
        supabase
                .from("checklist")
                .delete()
                .match({ id: props.item.id })
                .then(({ error }) => {
                    if (error) {
                        console.log(error)
                        setFormerror(error.message)
                    }
                    else {
                        props.onHide()
                        nav(0)
                    }
                });
    }
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this checklist?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ChecklistModal = (props) => {
    const [allChecked, setAllChecked] = useState(false)
    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState('')
    const [defects, setDefects] = useState(null)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [formerror, setFormerror] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    function checkAllBoxes() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        checkboxes.forEach((checkbox) => {
            checkbox.checked = true
        })
        setAllChecked(true)
    }
    const handleCheckboxes = (e) => {
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        const checked = []
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                checked.push(checkbox.value)
            }
        })
        if (checked.length === checkboxes.length) {
            setAllChecked(true)
        } else {
            setAllChecked(false)
        }
    }
    const handleUpdate = async (e) => {
        e.preventDefault()
        const checkboxes = document.querySelectorAll('input[type=checkbox]')
        const checklist = {}
        checkboxes.forEach((checkbox) => {
            checklist[checkbox.name] = checkbox.checked
        })
        supabase
                .from("checklist")
                .update([
                  {
                    vehicle_reg: vehicle_reg,
                    created_at: created,
                    checklist: checklist,
                    defects: defects
                  },
                ])
                .match({ id: props.item.id })
                .then(({ error }) => {
                  if (error) {
                    console.log(error)
                    setFormerror(error.message)
                  } 
                  else {
                    props.onHide()
                  }
                });
    }


    // function to delete driver
    const handleDelete = async (e) => {
        e.preventDefault()
        supabase
                .from("checklist")
                .delete()
                .match({ id: props.item.id })
                .then(({ error }) => {
                    if (error) {
                        console.log(error)
                        setFormerror(error.message)
                    }
                    else {
                        props.onHide()
                    }
                });
    }
    useEffect(() => {
        setVehicleReg(props.item.vehicle_reg)
        setCreated(props.item.created_at)
        setDefects(props.item.defects)

    }, [props])
    return (
        <Modal show={props.show} onHide={props.onHide} fullscreen>
            <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} item={props.item} />
            <Modal.Header closeButton>
                <Modal.Title>Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" placeholder="Enter date" onChange={(e) => setCreated(e.target.value)} defaultValue={created} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="vehicleReg">
                        <Form.Label>Vehicle Registration</Form.Label>
                        <Form.Control type="text" placeholder="Enter vehicle registration" onChange={(e) => setVehicleReg(e.target.value)} defaultValue={vehicle_reg} />
                    </Form.Group>
                    <Button className='my-3' variant="outline-primary" onClick={checkAllBoxes}>
                        Mark all as complete
                    </Button>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" name="Body Damage" label="Body Damage"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Body Damage'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Windscreen" label="Windscreen"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Windscreen'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Side / rear windows" label="Side / rear windows"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Side / rear windows'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Door functions" label="Door functions"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Door functions'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Wheel nuts" label="Wheel nuts"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Wheel nuts'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Tyres" label="Tyres"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Tyres'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Oil level" label="Oil level"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Oil level'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Water level" label="Water level"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Water level'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Mirrors" label="Mirrors"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Mirrors'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Fuel level" label="Fuel level"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Fuel level'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Wiper & washers" label="Wiper & washers"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Wiper & washers'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Indicators" label="Indicators"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Indicators'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Side / head lights" label="Side / head lights"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Side / head lights'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Interior lights" label="Interior lights"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Interior lights'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Brake lights" label="Brake lights"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Brake lights'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Steering" label="Steering"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Steering'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Brakes" label="Brakes"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Brakes'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Seat belts" label="Seat belts"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Seat belts'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Horn" label="Horn"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Horn'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Fluid leaks" label="Fluid leaks"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Fluid leaks'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Exhast" label="Exhast"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Exhast'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Fire extinguisher" label="Fire extinguisher"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Fire extinguisher'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="First aid box" label="First aid box"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['First aid box'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Door signs" label="Door signs"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Door signs'] ? true : false}
                        />
                        <Form.Check type="checkbox" name="Taxi plates" label="Taxi plates"
                            onChange={(e) => handleCheckboxes(e)}
                            defaultChecked={props.item.checklist['Taxi plates'] ? true : false}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="defects">
                        <Form.Label>Defects</Form.Label>
                        <Form.Control as="textarea" rows={3} 
                        onChange={(e) => setDefects(e.target.value)} 
                        placeholder='Please add defect details ' 
                        defaultValue={props.item.defects}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <span className="text-danger">{formerror}</span>
                {!formerror ?
                    <Button variant="primary" onClick={handleUpdate}>Update</Button>
                    :
                    <Button variant="primary" disabled>Update</Button>
                }
                    <Button variant="danger" onClick={() => setShowDeleteModal(true)}>Delete</Button>
                    
                
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChecklistModal