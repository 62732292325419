import { Table, Button, Spinner, Form, Modal, Alert, Badge, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import supabase from "../../config/supabaseClient"
import DriverModal from './DriverModal'
import { useNavigate } from 'react-router-dom';
import Feedback from 'react-bootstrap/esm/Feedback';

const FilterableTable = require('react-filterable-table');

const CreateDriverModal = () => {
    // Fotm inside modal to create a new user profile
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [show, setShow] = useState(false)
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleClose = () => {
        setShow(false)
        setError()
        setSuccess()
    }
    const handleShow = () => setShow(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (password !== confirmPassword) {
            setError('Passwords do not match')
            setLoading(false)
            return
        }
        supabase.auth.signUp({
            email: email, password: password
        }).then(({ data, error }) => {
            if (error) {
                setError(error.message)
                setLoading(false)
            } else {
                navigate(0)
            }
        })
    }
    return (
        <>


            <Button variant="success" onClick={handleShow}>
                Create Driver
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Driver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant='warning'>
                        <Alert.Heading>Warning</Alert.Heading>
                        <p>
                            This will create a new user profile and sign you in as that user.
                        </p>
                    </Alert>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter password" onChange={(e) => setConfirmPassword(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={loading}>
                            Submit
                        </Button>
                    </Form>
                    {error && <Alert variant='danger'>{error}</Alert>}
                    {success && <Alert variant='success'>{success}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
const ViewDrivers = () => {
    const [drivers, setDrivers] = useState()
    const [inactiveDrivers, setInactiveDrivers] = useState()
    const [inactive, setInactive] = useState(false)
    const [showDriverModal, setShowDriverModal] = useState(false)
    const [driver, setDriver] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const renderModalButton = (props) => {
        return (
            <Button variant="primary" onClick={() => navigate(props.record.user_id, { state: { view: 'profile' } })}>
                View
            </Button>
        )
    }
    const renderAdmin = (props) => {
        return (
            <div>
                {props.value ? <Badge bg="info">Admin</Badge> : null}
                {props.record.active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
            </div>
        )
    }
    const renderName = (props) => {
        return (
            <div>
                {props.record.fname} {props.record.lname}
            </div>
        )
    }
    const fields = [
        { name: 'user_simple_id', displayName: "ID", inputFilterable: true, exactFilterable: false, sortable: true },
        { name: 'fname', displayName: "Name", inputFilterable: true, exactFilterable: false, sortable: true, render: renderName },
        { name: 'email', displayName: "Email", inputFilterable: true, exactFilterable: false, sortable: true },
        { name: 'phone', displayName: "Phone", inputFilterable: true, exactFilterable: false, sortable: true },
        { name: 'isadmin', displayName: "Status", inputFilterable: true, exactFilterable: false, sortable: true, render: renderAdmin },
        { name: 'user_id', displayName: "Actions", inputFilterable: false, exactFilterable: false, sortable: false, render: renderModalButton }
    ];

    const fetchDrivers = async () => {
        setLoading(true)
        await supabase
            .from('user_profiles')
            .select('*')
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    let activeDrivers = data.filter(driver => driver.active)
                    let inactiveDrivers = data.filter(driver => !driver.active)
                    setDrivers(activeDrivers)
                    setInactiveDrivers(inactiveDrivers)
                    setTimeout(() => {
                        setLoading(false)
                    } , 500)
                }
            })
    }
    useEffect(() => {
        fetchDrivers()

    }, [driver])
    return (
        <>
            <Row className='p-2 py-3'>
                <Col sm={7}>
                    {inactive ? <h1 className='text-start'>Inactive Drivers </h1> 
                    : <h1 className='text-start'>Active Drivers</h1>}
                </Col>
                <Col sm={5} >
                    <Button variant="info" className='m-2' onClick={() => {fetchDrivers(); setLoading(true)}}>
                        {
                            loading ? <Spinner animation="border" variant="dark" size='sm' /> : 
                            <>Refresh <i className="fa fa-refresh" aria-hidden="true" ></i></>
                        }
                    </Button>
                    <CreateDriverModal />
                    <Button
                        variant='secondary'
                        onClick={() => { setDrivers(inactiveDrivers); setInactiveDrivers(drivers); setInactive(!inactive) }}
                        className='m-2'
                    >
                        Toggle Inactive Drivers
                    </Button>
                </Col>
            </Row>
            <FilterableTable
                namespace="Drivers"
                initialSort="user_simple_id"
                data={drivers ? drivers : []}
                fields={fields}
                noRecordsMessage="There are no drivers to display"
                noFilteredRecordsMessage="No drivers match your filters!"
                loadingMessage="Loading drivers..."
                pageSize={200}
                pageSizes={[5, 10, 20, 50, 100, 200]}
                topPagerVisible={false}
                bottomPagerVisible={true}
                tableClassName="table table-striped table-bordered table-hover shadow"
            />

        </>
    )
}

export default ViewDrivers