import { Button, Modal, Form } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import supabase from "../../../config/supabaseClient"

const ViewFullChecklist = (props) => {
    const [vehicle_reg, setVehicleReg] = useState('')
    const [created, setCreated] = useState('')
    const [defects, setDefects] = useState(null)
    useEffect(() => {
        setVehicleReg(props.item.vehicle_reg)
        setCreated(props.item.created_at)
        setDefects(props.item.defects)

    }, [props])
    return (
        <Modal show={props.show} onHide={props.onHide} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control disabled type="date" placeholder="Enter date" defaultValue={created} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="vehicleReg">
                        <Form.Label>Vehicle Registration</Form.Label>
                        <Form.Control disabled type="text" placeholder="Enter vehicle registration" defaultValue={vehicle_reg} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check disabled type="checkbox" name="Body Damage" label="Body Damage"
                            
                            defaultChecked={props.item.checklist['Body Damage'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Windscreen" label="Windscreen"
                            
                            defaultChecked={props.item.checklist['Windscreen'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Side / rear windows" label="Side / rear windows"
                            
                            defaultChecked={props.item.checklist['Side / rear windows'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Door functions" label="Door functions"
                            
                            defaultChecked={props.item.checklist['Door functions'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Wheel nuts" label="Wheel nuts"
                            
                            defaultChecked={props.item.checklist['Wheel nuts'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Tyres" label="Tyres"
                            
                            defaultChecked={props.item.checklist['Tyres'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Oil level" label="Oil level"
                            
                            defaultChecked={props.item.checklist['Oil level'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Water level" label="Water level"
                            
                            defaultChecked={props.item.checklist['Water level'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Mirrors" label="Mirrors"
                            
                            defaultChecked={props.item.checklist['Mirrors'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Fuel level" label="Fuel level"
                            
                            defaultChecked={props.item.checklist['Fuel level'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Wiper & washers" label="Wiper & washers"
                            
                            defaultChecked={props.item.checklist['Wiper & washers'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Indicators" label="Indicators"
                            
                            defaultChecked={props.item.checklist['Indicators'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Side / head lights" label="Side / head lights"
                            
                            defaultChecked={props.item.checklist['Side / head lights'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Interior lights" label="Interior lights"
                            
                            defaultChecked={props.item.checklist['Interior lights'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Brake lights" label="Brake lights"
                            
                            defaultChecked={props.item.checklist['Brake lights'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Steering" label="Steering"
                            
                            defaultChecked={props.item.checklist['Steering'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Brakes" label="Brakes"
                            
                            defaultChecked={props.item.checklist['Brakes'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Seat belts" label="Seat belts"
                            
                            defaultChecked={props.item.checklist['Seat belts'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Horn" label="Horn"
                            
                            defaultChecked={props.item.checklist['Horn'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Fluid leaks" label="Fluid leaks"
                            
                            defaultChecked={props.item.checklist['Fluid leaks'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Exhast" label="Exhast"
                            
                            defaultChecked={props.item.checklist['Exhast'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Fire extinguisher" label="Fire extinguisher"
                            
                            defaultChecked={props.item.checklist['Fire extinguisher'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="First aid box" label="First aid box"
                            
                            defaultChecked={props.item.checklist['First aid box'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Door signs" label="Door signs"
                            
                            defaultChecked={props.item.checklist['Door signs'] ? true : false}
                        />
                        <Form.Check disabled type="checkbox" name="Taxi plates" label="Taxi plates"
                            
                            defaultChecked={props.item.checklist['Taxi plates'] ? true : false}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="defects">
                        <Form.Label>Defects</Form.Label>
                        <Form.Control as="textarea" rows={3} 
                        placeholder='Please add defect details ' 
                        defaultValue={props.item.defects}
                        disabled
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewFullChecklist