import { Table, Button, Spinner, Accordion, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import supabase from "../../config/supabaseClient"
import ChecklistModal from './ChecklistModal'
import moment from 'moment/moment'
import { useNavigate } from 'react-router'
import CreateChecklistModal from './checklist/CreateChecklistModal'
const FilterableTable = require('react-filterable-table');
const ViewChecklists = () => {
    const [checklistCount, setChecklistCount] = useState(0)
    const [checklists, setChecklists] = useState()
    const [showChecklistModal, setShowChecklistModal] = useState(false)
    const [selectedChecklist, setSelectedChecklist] = useState()
    const [incompleteChecklists, setIncompleteChecklists] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const renderModalButton = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => { setShowChecklistModal(true); setSelectedChecklist(props.record) }}>
                    View
                </Button>
                <Button variant='primary' className='mx-2' onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'checklists' } })}>
                    View all driver checklists
                </Button>
            </div>
        )
    }
    const renderDefects = (props) => {
        const falseChecklist = Object.keys(props.record.checklist).filter(key => props.record.checklist[key] === false)
        return (
            <ul>
                {falseChecklist.map((defect) => {
                    return (
                        <li key={defect} className='mx-1'>
                            {defect}
                        </li>
                    )
                })}
                {props.record.defects}
            </ul>
        )

    }
    const renderDate = (props) => {
        return (
            <div>
                {moment(props.record.created_at).format('DD-MM-YYYY')}
            </div>
        )
    }
    const renderName = (props) => {
        return (
            <div>
                {props.record.user_profiles.fname} {props.record.user_profiles.lname}
            </div>
        )
    }
    const fields = [
        { name: 'user_profiles.user_simple_id', displayName: "Driver ID", inputFilterable: true, exactFilterable: true, sortable: true },
        { name: 'user_profiles.fname', displayName: "Driver Name", inputFilterable: true, exactFilterable: true, sortable: true, render: renderName },
        { name: 'created_at', displayName: "Date", inputFilterable: true, exactFilterable: true, sortable: true, render: renderDate },
        { name: 'vehicle_reg', displayName: "Reg", inputFilterable: true, exactFilterable: true, sortable: false },
        { name: 'defects', displayName: "Defects", inputFilterable: true, exactFilterable: false, sortable: false, render: renderDefects },
        { name: 'user_id', displayName: "Actions", inputFilterable: false, exactFilterable: false, sortable: false, render: renderModalButton }
    ];
    const fetchChecklists = async () => {
        await supabase
            .from('checklist')
            .select('*, user_profiles(*)')
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    setChecklists(data)
                    setTimeout(() => {
                        setLoading(false)
                    }, 500)
                }
            })
    }

    // function to get all checklists made today using Date() and .toDateString()
    const getIncompleteChecklists = async () => {
        await supabase
            .from('user_profiles')
            .select('*, checklist(*)')
            .filter('active', 'eq', true)
            .filter('isadmin', 'eq', false)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                } else {
                    // filter out all checklists that have been completed today using moment
                    const incompleteChecklists = data.filter((checklist) => {
                        return checklist.checklist.filter((checklist) => {
                            return moment(checklist.created_at).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
                        }).length === 0
                    }
                    )
                    setIncompleteChecklists(incompleteChecklists)
                }
            })
    }


    useEffect(() => {

        fetchChecklists()
        getIncompleteChecklists()
    }, [])
    return (
        <div>
            <Row className='p-2 py-3'>
                <Col sm={8}>
                    <h1 className='text-start' 
                        onClick={() => {setChecklistCount(checklistCount + 1)}}
                    >Checklists</h1>
                </Col>
                <Col sm={4} className='text-end'>
                    <Button variant="info" className='m-2' onClick={() => { setLoading(true); fetchChecklists(); getIncompleteChecklists(); setLoading(true) }}>
                        {loading ? 
                            <Spinner animation="border" variant="dark" size='sm' /> :
                            <>Refresh <i className="fa fa-refresh" aria-hidden="true" ></i></>
                        }
                    </Button>
                    {
                        checklistCount > 4 ?
                        <CreateChecklistModal refresh={() => { setLoading(true); fetchChecklists(); getIncompleteChecklists(); setLoading(true); console.log('refresh') }} />
                        : null
                    }
                </Col>

            </Row>
            <Accordion defaultActiveKey="0" className='shadow overflow-hidden rounded mx-5 mb-3'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Checklists not completed today <span className="m-1 badge bg-danger">{incompleteChecklists.length}</span></Accordion.Header>
                    {
                        incompleteChecklists.length === 0 ? <Accordion.Body className='text-center'>All drivers have completed their checklists today</Accordion.Body> :

                            <Accordion.Body >
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Driver ID</th>
                                            <th>Driver Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {incompleteChecklists.map((checklist) => {
                                            return (
                                                <tr key={checklist.user_id}>
                                                    <td>{checklist.user_simple_id}</td>
                                                    <td>{checklist.fname} {checklist.lname}</td>
                                                    <td><Button>Send reminder</Button> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                    }
                </Accordion.Item>
            </Accordion>

            {showChecklistModal ? <ChecklistModal item={selectedChecklist} show={showChecklistModal} onHide={() => { setShowChecklistModal(false); fetchChecklists(); setSelectedChecklist(null) }} /> : null}
            <FilterableTable
                namespace="Checklists"
                initialSort="created_at"
                initialSortDir={false}
                data={checklists ? checklists : []}
                fields={fields}
                noRecordsMessage="There are no checklists to display"
                noFilteredRecordsMessage="No checklists match your filters!"
                loadingMessage="Loading checklists..."
                pageSizes={[5, 10, 20, 50, 100]}
                topPagerVisible={false}
                bottomPagerVisible={true}
                tableClassName="table table-striped table-bordered table-hover shadow rounded"
            />
        </div>
    )
}

export default ViewChecklists