import { RealtimeChannel, Session } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from '../config/supabaseClient'

const UseSession = () => {
	const [session, setSession] = useState();
	const [profile, setProfile] = useState();
	const [channel, setChannel] = useState();
	const navigate = useNavigate();
	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			supabase.auth.onAuthStateChange((_event, session) => {
				setSession(session);
				checkUserProfileExists(session?.user?.id)
			});
		});
		// setTimeout(() => {

		// }, 500);
	}, []);
	useEffect(() => {
		if (session?.user && !profile) {
			listenToUserProfileChanges(session.user.id).then(
				(newChannel) => {
					if (channel) {
						channel.unsubscribe();
					}
					setChannel(newChannel);
				}
			)

		} else if (!session?.user) {
			channel?.unsubscribe();
			setChannel(null);
		}
	}, [session]);

	async function checkUserProfileExists(user_id) {
		supabase
			.from("user_profiles")
			.select("*")
			.filter("user_id", "eq", user_id)
			.then(({ data, error }) => {
				if (error) {
					console.log(error)
					return null;
				}
				else {
					if (data?.[0]) {
						if (profile?.user_id !== data?.[0]?.user_id) {
							setProfile(data?.[0])
						}
					}
				}
			})
	}

	async function listenToUserProfileChanges(user_id) {
		const { data } = await supabase
			.from("user_profiles")
			.select("*")
			.filter("user_id", "eq", user_id);
		if (!data?.length) {
			navigate("/registration");
		}
		if (data?.[0]) {
			//check if user is active
			if (!data?.[0]?.active) {
				supabase.auth.signOut();
				navigate("/login");
			}
			setProfile(data?.[0]);
		}
		return supabase
			.channel(`public:user_profiles`)
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "user_profiles",
					filter: `user_id=eq.${user_id}`,
				},
				(payload) => {
					console.log("payload", payload);
					setProfile(payload.new);
				}
			)
			.subscribe();
	}
	return { session, profile, channel };
}

export default UseSession;