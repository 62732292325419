import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../../config/supabaseClient'
import { UserContext } from '../../../App2'
import DocEditModal from '../docs/DocEditModal'
import AddDocModal from './AddDocModal'
import moment from 'moment/moment'
const FilterableTable = require('react-filterable-table');

const ViewDocs = (props) => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState([])
    const [inactiveDocs, setInactiveDocs] = useState([])
    const [showImageModal, setShowImageModal] = useState(false)
    const [image_urls, setImageUrls] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [doc, setDoc] = useState(null)
    const [viewInactive, setViewInactive] = useState(false)
    const renderModalButton = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => { setShowEditModal(true); setDoc(props.record) }}>
                    Edit
                </Button>
            </div>
        )
    }
    const renderExpiry = (props) => {
        let startDate = moment(props.record.expiry)
        let reminderdays = moment(props.record.document_types.reminder_days)
        let endDate = moment()
        let duration = moment.duration(startDate.diff(endDate))
        let days = duration.asDays()
        let reminder = moment.duration(reminderdays.diff(endDate))
        let reminderdaysleft = reminder.asDays()

        if (days < 0) {
            return (
                <div className='bg-danger text-light'>
                    {props.record.expiry ? props.record.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else if (days < reminderdays) {
            return (
                <div className='bg-warning'>
                    {props.record.expiry ? props.record.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else {
            return (
                <div>
                    {props.record.expiry ? props.record.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        }
    }
    const fields = [
        { name: 'document_types.doc_name', displayName: "Document Name", inputFilterable: true, exactFilterable: false, sortable: true },
        { name: 'expiry', displayName: "Expiry Date", inputFilterable: true, exactFilterable: false, sortable: true, render: renderExpiry },
        { name: 'id', displayName: "Actions", inputFilterable: false, exactFilterable: false, sortable: false, render: renderModalButton }
    ];
    useEffect(() => {
        supabase
            .from('documents')
            .select('*, user_profiles(*), document_types(*)')
            .eq('user_id', props.user_id)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    let temp = []
                    let inactiveDocs = []
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].active === true) {
                            temp.push(data[i])
                        }
                    }
                    setRes(temp)
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].active === false) {
                            inactiveDocs.push(data[i])
                        }
                    }
                    setInactiveDocs(inactiveDocs)
                }
            })
    }, [])
    return (
        <>
            {showEditModal ? <DocEditModal item={doc} show={showEditModal} onHide={() => { setShowEditModal(false); setDoc(null) }} /> : null}

            <Row className='p-2 py-3'>
                <Col sm={8}>
                    {!viewInactive ? <h3 >Active Documents</h3> : <h3 className='bg-warning'>Inactive Documents</h3>}
                </Col>
                <Col sm={4} className='text-end'>
                    <AddDocModal user_id={props.user_id} />
                    <Button
                        variant='secondary'
                        onClick={() => { setRes(inactiveDocs); setInactiveDocs(res); setViewInactive(!viewInactive) }}
                    >
                        View Inactive Documents
                    </Button>

                </Col>
            </Row>
            <FilterableTable
                namespace="Documents"
                initialSort="expiry"
                initialSortDir={true}
                data={res ? res : []}
                fields={fields}
                topPagerVisible={false}
                noRecordsMessage="There are no documents to display"
                noFilteredRecordsMessage="No documents match your filters!"
                tableClassName="table table-striped table-bordered table-hover shadow rounded"
            />

        </>
    )
}

export default ViewDocs
