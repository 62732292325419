import { Button, Modal, Form, Stack, Row, Col, Alert, Badge } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import supabase from "../../../config/supabaseClient"
import userEvent from '@testing-library/user-event'

const DocTypes = () => {
    const [show, setShow] = useState(false)
    const [initDocTypes, setInitDocTypes] = useState([])
    const [docTypes, setDocTypes] = useState([])
    const [newDocType, setNewDocType] = useState('')
    const [reminderDays, setReminderDays] = useState(0)
    const [required, setRequired] = useState(false)
    const [formError, setFormError] = useState('')
    const [changed, setChanged] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [deleteID, setDeleteID] = useState('')
    const [deleteName, setDeleteName] = useState('')
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [deleteError, setDeleteError] = useState('')

    const [showUpdate, setShowUpdate] = useState(false)
    const [updateID, setUpdateID] = useState('')
    const [updateName, setUpdateName] = useState('')
    const [updateReminderDays, setUpdateReminderDays] = useState(0)
    const [updateError, setUpdateError] = useState('')

    const [order, setOrder] = useState([])
    const [updateOrder, setUpdateOrder] = useState([])

    const navigate = useNavigate()
    
    const fetchDocTypes = async () => {
        const { data, error } = await supabase
            .from('document_types')
            .select('*')
            .order('order', { ascending: true })
        if (error) {
            console.log(error)
        } else {
            setInitDocTypes(data)
            setDocTypes(data)
        }
    }
    const moveUp = (id) => {
        const index = docTypes.findIndex((item) => item.id === id)
        const newIndex = index - 1
        if (newIndex < 0) {
            return
        }
        const newOrder = [...docTypes]
        newOrder[newIndex].order = newOrder[newIndex].order + 1
        newOrder[index].order = newOrder[index].order - 1
        newOrder[index] = newOrder[newIndex]
        newOrder[newIndex] = docTypes[index]
        setDocTypes(newOrder)
    }
    const moveDown = (id) => {
        const index = docTypes.findIndex((item) => item.id === id)
        const newIndex = index + 1
        if (newIndex >= docTypes.length) {
            return
        }
        const newOrder = [...docTypes]
        newOrder[newIndex].order = newOrder[newIndex].order - 1
        newOrder[index].order = newOrder[index].order + 1
        newOrder[index] = newOrder[newIndex]
        newOrder[newIndex] = docTypes[index]
        setDocTypes(newOrder)
    }

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const handleDeleteClose = () => setShowDelete(false)
    const handleDeleteShow = (id, name) => {setShowDelete(true); setDeleteID(id); setDeleteName(name)}
    const handleUpdateClose = () => setShowUpdate(false)
    const handleUpdateShow = (id, name, rem_days, required) => {setShowUpdate(true); setUpdateID(id); setUpdateName(name); setUpdateReminderDays(rem_days); setRequired(required)}

    const handleNewDocType = (input) => {
        setNewDocType(input)
        setChanged(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { error } = await supabase
            .from('document_types')
            .insert([
                {
                    doc_name: newDocType,
                    reminder_days: reminderDays,
                    required: required,
                },
            ])
        if (error) {
            setFormError(error.message)
        } else {
            setChanged(true)
            setShowSuccess(true)
            handleClose()
            fetchDocTypes()
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault()
        const { error } = await supabase
            .from('document_types')
            .delete()
            .match({ id: deleteID })
        if (error) {
            setDeleteError(error.message)
        } else {
            setDeleteConfirm(true)
            setChanged(true)
            handleDeleteClose()
            fetchDocTypes()
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        const { error } = await supabase
            .from('document_types')
            .update([
                {
                    doc_name: updateName,
                    reminder_days: updateReminderDays,
                    required: required,
                },
            ])
            .match({ id: updateID })
        if (error) {
            setFormError(error.message)
        } else {
            setChanged(true)
            setShowSuccess(true)
            handleUpdateClose()
            fetchDocTypes()
        }
    }
    const handleSave = async () => {
        // save each item in docTypes to the database with the new order
        docTypes.forEach(async (item) => {
            const { error } = await supabase
                .from('document_types')
                .update([
                    {
                        order: item.order,
                    },
                ])
                .match({ id: item.id })
            if (error) {
                console.log(error)
            } else {
                setChanged(true)
                setShowSuccess(true)
                fetchDocTypes()
            }
        }
        )
    }
    
    useEffect(() => {
        fetchDocTypes()
    }, [])
    useEffect(() => {
        if (docTypes !== initDocTypes) {
            setChanged(true)
        }
    }, [docTypes])
    return (
        <div>
            <Row className='p-2 py-3'>
                <Col sm={8}>
                    <h1 className='text-start'>Document Types</h1>
                </Col>
                <Col sm={4}>
                    <Button variant='outline-primary' onClick={() => navigate(-1)}><i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back</Button>
                    <Button variant='success' onClick={handleShow} className='mx-2'>Add Document Type</Button>
                    
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Document Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId='formDocType'>
                            <Form.Label>Document Type</Form.Label>
                            <Form.Control type='text' placeholder='Enter Document Type' onChange={(e) => handleNewDocType(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId='formReminderDays'>
                            <Form.Label>Reminder Days </Form.Label>
                            <Form.Control type='number' placeholder='Enter Reminder Days' onChange={(e) => setReminderDays(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId='formRequired'>
                            <Form.Check type='checkbox' label='Required' onChange={(e) => setRequired(e.target.checked)} checked={required?true:false} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Stack gap={2}>
                        <Button variant='secondary' onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='primary' type='submit'>
                            Save Changes
                        </Button>
                        </Stack>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDelete} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Alert variant='warning'>
                    <Alert.Heading>Warning</Alert.Heading>
                    <p>
                        Deleting a document type will also delete all documents of that type.
                    </p>
                </Alert>
                    <p>Are you sure you want to delete {deleteName}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleDeleteClose}>
                        Close
                    </Button>
                    <Button variant='danger' onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdate} onHide={handleUpdateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId='formDocType'>
                        <Form.Label>Document Type</Form.Label>
                        <Form.Control type='text' placeholder='Enter Document Type' onChange={(e) => setUpdateName(e.target.value)} value={updateName} />
                    </Form.Group>
                    <Form.Group controlId='formReminderDays'>
                        <Form.Label>Reminder Days</Form.Label>
                        <Form.Control type='number' placeholder='Enter Reminder Days' onChange={(e) => setUpdateReminderDays(e.target.value)} defaultValue={updateReminderDays} />
                    </Form.Group>
                    <Form.Group controlId='formRequired'>
                            <Form.Check type='checkbox' label='Required' onChange={(e) => setRequired(e.target.checked)} checked={required?true:false} />
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleUpdateClose}>
                        Close
                    </Button>
                    <Button variant='primary' onClick={handleUpdate}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Document Type Updated</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={() => setShowSuccess(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Document Type Deleted</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={() => setDeleteConfirm(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <table className='table table-sm table-striped table-bordered table-hover shadow'>
                <thead>
                    <tr>
                        <th scope='col' className='ml-4'>Document Type</th>
                        <th scope='col'>Reminder Days</th>
                        <th scope='col'>Actions</th>
                        <th scope='col'>Order</th>
                    </tr>
                </thead>
                <tbody>
                    {docTypes.map((docType) => (
                        // List of document types using order array
                        <tr key={docType.id}>
                            <td>{docType.doc_name}</td>
                            <td>
                                {docType.reminder_days}
                                {
                                    docType.required ?
                                    <Badge bg='danger' className='m-2'>Required</Badge> :
                                    <Badge bg='success' className='m-2'>Optional</Badge>
                                }
                            </td>
                            <td>
                                <Stack gap={1} className='text-center'>
                                <Button variant='primary' onClick={() => handleUpdateShow(docType.id, docType.doc_name, docType.reminder_days, docType.required)}>Update</Button>
                                <Button variant='danger' onClick={() => handleDeleteShow(docType.id, docType.doc_name)}>Delete</Button>
                                </Stack>
                            </td>
                            <td>
                                <Stack gap={1} className='text-center'>
                                    <span>{docType.order} - {docType.doc_name}</span>
                                    <Button variant='outline-primary' onClick={() => moveUp(docType.id)}><i className="fa-solid fa-up-long"></i></Button>
                                    <Button variant='outline-primary' onClick={() => moveDown(docType.id)}><i className="fa-solid fa-down-long"></i></Button>
                                </Stack>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {
                changed ? <Button variant='primary' onClick={() => handleSave()}>Save</Button> :
                <Button variant='primary' disabled>Save</Button>
            }
        </div>
    )
}

export default DocTypes